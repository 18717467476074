<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'TariffGroupType', title: 'Групи тарифу' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_group_type)"
        :modal="show_tariff_group_type_dialog"
        @updateItemModal="tariffGroupTypeUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" :class="!selectable ? 'bordered' : '' " min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Перелік груп тарифів
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openTariffGroupTypeCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити нову групу тарифу</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="filteredItems"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1]
                }"
              class="custom-table custom-table-1"
              @click:row="onTariffGroupTypeItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.create_date="{ item }">
              {{ item.create_date | formatDate }}
            </template>
            <template v-slot:item.icon>
              <v-icon size="26" color="primary lighten-1" class="mr-2">
                mdi-account-group
              </v-icon>
            </template>
            <template v-slot:item.detalization="{ item }">
              {{ getRegularDetails(item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getRegularDetails, getAccountIcon} from "@/utils/icons"
import {FETCH_TARIFF_GROUP_TYPE} from "@/store/actions/tariff_group";

export default {
  props: ['selectable', 'model', 'service_id'],
  name: "TariffGroupView.vue",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getTariffGroupType'
    }),
    filteredItems() {
      return this.items
    }
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        {text: '', value: 'icon', width: 26},
        {text: 'Назва', value: 'name'},
        {text: 'Створено', value: 'create_date'},
        {text: 'Автор', value: 'owner_name'},
      ],
      selected_tariff_group_type: {},
      show_tariff_group_type_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_TARIFF_GROUP_TYPE
    }),
    getRegularDetails,
    getAccountIcon,
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    tariffGroupTypeUpdateItemModal() {
      this.show_tariff_group_type_dialog = false
      this.selected_tariff_group_type = {}
    },
    openTariffGroupTypeCreateDialog() {
      this.selected_tariff_group_type = {}
      this.show_tariff_group_type_dialog = true
    },
    onTariffGroupTypeItemClick(payload) {
      this.selected_tariff_group_type = JSON.parse(JSON.stringify(payload))
      this.show_tariff_group_type_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">
  .bordered {
    border-left: 3px solid #fafafa;
  }
</style>